<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block />

        {{ page_title }}
      </h3>

    </div>

    <div class="box-body">
      <transition name="fade">
        <div class="form-group">
          <div class="row">
            <label class="col-xl-3 col-md-6 col-sm-12 mb-1" for="filter-by-branch">
              <multiselect :allow-empty="true"
                           :options="branches"
                           :searchable="false"
                           :show-labels="true"
                           id="filter-by-branch"
                           @input="() => {
                             filter_finances.branch_id = branch ? branch.id : null;
                             getEmployees(filter_finances.branch_id);
                             getFinances();
                           }"
                           deselect-label="Убрать"
                           label="name"
                           placeholder="Филиал"
                           required
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Филиал"
                           track-by="id"
                           v-model="branch">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
                <template slot="singleLabel" slot-scope="props">
                  {{ props.option.title }}, {{ props.option.address }}
                </template>
                <template slot="option" slot-scope="props">
                  {{ props.option.title }}, <span class="text-muted">{{ props.option.address }}</span>
                </template>
              </multiselect>
            </label>

            <label class="col-xl-3 col-md-6 col-sm-12 mb-1" for="filter-by-responsible">
              <multiselect :allow-empty="true"
                           :options="employees"
                           :searchable="true"
                           id="filter-by-responsible"
                           @input="() => {
                             filter_finances.responsible_id = responsible ? responsible.id : null;
                             getFinances();
                           }"
                           deselect-label="Убрать"
                           label="title"
                           placeholder="Ответственный"
                           required
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Ответственный"
                           track-by="id"
                           v-model="responsible">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
              </multiselect>
            </label>
          </div>
        </div>
      </transition>

      <div v-if="report_items && onProgress === false">
        <div class="row" v-for="(reports, title) in report_items">

          <div class="box-header">
            <h4 class="font-medium">
              {{ title }}
            </h4>
          </div>
          <div class="table-responsive">
            <table class="table table-condensed">
              <thead>
              <tr>
                <th>Дата</th>
                <th></th>
                <th>Автомобиль</th>
                <th>Менеджер</th>
                <th>В руки</th>
                <th>Сумма продажи</th>
                <th>Комиссия</th>
                <th>Комментарий</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(report_item, index) in reports" :key="report_item.id">
                <td class="nowrap-column font-medium" style="width: 100px;">
                  {{ report_item.created_date }}
                </td>
                <td style="width: 150px;">
                  <img v-if="report_item.car.main_photo"
                       :src="report_item.car.main_photo"
                       class="img-fluid"
                       :alt="report_item.car.title"
                       style="max-height: 80px;">
                  <img :alt="report_item.car.title"
                       class="img-fluid"
                       src="../../assets/img/no-car-photo-240-140.png"
                       v-else>
                </td>
                <td style="width: 300px;">
                  <strong>
                    {{ report_item.car.title }}, {{ report_item.car.year }} г.
                  </strong>
                  <br />
                  {{ report_item.car.branch.title }}, {{ report_item.car.branch.address }}
                  <br />
                </td>
                <td style="width: 200px;">
                  <strong>
                    {{ report_item.creator.name }}
                  </strong>
                  (ID {{ report_item.creator.id }})
                </td>
                <td style="width: 120px;">
                  <strong>
                    ${{ report_item.owner_sum }}
                  </strong>
                </td>
                <td style="width: 150px;">
                  <strong>
                    ${{ report_item.sum }}
                  </strong>
                </td>
                <td style="width: 120px;">
                  <strong>
                    ${{ (report_item.sum - report_item.owner_sum) }}
                  </strong>
                </td>
                <td style="width: 250px;">
                  <small>
                    {{ report_item.comment }}
                  </small>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <hr class="mt-3 mb-4" />
        </div>
      </div>
      <loading-processing v-else></loading-processing>
    </div>
  </div>

</template>

<script>
import Multiselect from 'vue-multiselect';
import BranchService from "@/services/BranchService";

export default {
  name: "finances",

  components: {
    Multiselect,
  },

  props: {},

  metaInfo() {
    return {
      title: this.page_title,
    };
  },

  data: () => ({
    page_title: "Финансы",

    report_items: null,

    branches: [],
    branch: null,
    employees: [],
    responsible: null,

    filter_finances: {
      branch_id: null,
      responsible_id: null,
    },

    onProgress: true,
  }),

  async created() {
    try {
      const employees_response = await API.apiClient.get("/employees", {
        params: this.$apiRequestParams.filters_list,
      });
      this.employees = employees_response.data;

      this.branches = await BranchService.getItems();

      await this.getFinances();
    } catch (error) {
      errorHandler(error);
    }

    this.onProgress = false;
  },

  methods: {
    async getFinances() {
      try {
        this.onProgress = true;

        const cars_sold_response = await API.apiClient.get("/cars/sold", {
          params: {
            filters_query: {
              is_paginated: 0,
              branch_id: this.filter_finances.branch_id,
              responsible_id: this.filter_finances.responsible_id,
            },
          },
        });

        this.report_items = cars_sold_response.data;
      } catch (error) {
        errorHandler(error);
      }
      this.onProgress = false;
    },

    async getEmployees(branchID = null) {
      try {
        const employees_response = await API.apiClient.get("/employees", {
          params: {
            filters_query: {
              is_paginated: 0,
              branch_id: branchID,
            },
          },
        });

        this.employees = employees_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },
  },
};
</script>

<style scoped>

</style>
